import {CitiBikeStationsLayer} from './layers/citiBikeStations';
import {TripsLayer} from './layers/trips';

export default [
  /* 0 */ {
    layers: [TripsLayer],
    view: {latitude: 40.75, longitude: -74.0, bearing: 0, pitch: 0, zoom: 12}
  },
  /* 1 */ {
    mapID: '95e3b629-bb5c-457f-9743-bf410f5e29f0',
    orbit: true,
  },
  /* 2 */ {
    layers: [CitiBikeStationsLayer],
    view: {latitude: 40.72, longitude: -73.95, bearing: 0, pitch: 50, zoom: 12},
    orbit: true
  },
];
