import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useAppState } from '../../state';
import { SIDEBAR_WIDTH } from '../Sidebar/Sidebar';
import CoverLogo from '../Cover/CoverLogo';

import { DeckGL } from '@deck.gl/react';
import { Map as ReactMapGLMap } from 'react-map-gl';
import maplibregl from 'maplibre-gl';

import { createRequestTransformer } from "amazon-location-helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    flexGrow: 1,
    transition: theme.transitions.create(['margin', 'background-image'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  rootShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginBottom: theme.spacing(15),
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      height: '100%',
      marginRight: `${SIDEBAR_WIDTH.xs}`,
      [theme.breakpoints.up('lg')]: {
        marginRight: `${SIDEBAR_WIDTH.lg}`
      }
    }
  },
  rootBlocked: {
    pointerEvents: 'none'
  },
  mapBlock: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundImage:
      'linear-gradient(to bottom, rgba(15, 17, 20, 0.84), rgba(15, 17, 20, 0.8) 65%, rgba(15, 17, 20, 0.2))',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  mapBlockHidden: {
    opacity: 0,
    pointerEvents: 'none'
  }
}));

const Map = () => {
  const classes = useStyles();
  const {currentSlide, layers, viewState} = useAppState();

  // const identityPoolId = "us-east-2:303d12f6-e24e-4571-8a79-66cc7c6a6bdc"; // Cognito Identity Pool ID (vizzuality)
  const identityPoolId = "us-east-1:f89794f8-ca3f-485b-80da-cc4555463510"; // Cognito Identity Pool ID (carto-published-resources)
  const [transformRequest, setRequestTransformer] = useState();

  // create a new transformRequest function whenever the credentials change
  useEffect(() => {
    const makeRequestTransformer = async () => {
      const tr = await createRequestTransformer({
        identityPoolId,
      });
      // wrap the new value in an anonymous function to prevent React from recognizing it as a
      // function and immediately calling it
      setRequestTransformer(() => tr);
    };

    makeRequestTransformer();
  }, []);

  return (
    <>
      <div
        className={[classes.root, currentSlide > 0 ? classes.rootShift : classes.rootBlocked].join(
          ' '
        )}
        id="map"
      >
        {transformRequest && (
          <DeckGL 
            initialViewState={viewState} 
            controller={true} 
            layers={layers}
          >
            <ReactMapGLMap mapStyle='HereExplore' mapLib={maplibregl} transformRequest={transformRequest} />
          </DeckGL>
        )}
      </div>
      <CoverLogo />
      <div
        className={[classes.mapBlock, currentSlide > 0 ? classes.mapBlockHidden : ''].join(' ')}
      />
    </>
  );
};

export default Map;
