import React, {useCallback, useState, createContext, useContext, useEffect} from 'react';

import slides from './slides';

import { LinearInterpolator, FlyToInterpolator } from '@deck.gl/core';
import { fetchMap, setDefaultCredentials } from '@deck.gl/carto';

const initAppState = {
  currentSlide: null,
  viewState: {
    longitude: -73.9,
    latitude: 40.75,
    zoom: 12,
    pitch: 50
  },
};

export const AppStateContext = createContext(initAppState);

const transitionInterpolator = new LinearInterpolator(['bearing', 'longitude', 'latitude']);

export const AppStateStore = ({children}) => {
  const [currentSlide, setCurrentSlide] = useState(initAppState.currentSlide);
  const [layers, setLayers] = useState([]);
  const [viewState, setViewState] = useState(initAppState.viewState);

  const orbit = useCallback(() => {
    setViewState(viewState => ({
      ...viewState,
      bearing: viewState.bearing + 120,
      transitionDuration: 20000,
      transitionInterpolator,
      onTransitionEnd: orbit 
    }))
  }, []);

  const updateViewState = function(viewState, shouldOrbit) {
    setViewState({
      ...viewState,
      transitionDuration: 4000,
      transitionInterpolator: new FlyToInterpolator(),
      onTransitionEnd: () => {
        if (shouldOrbit) {
          orbit();
        }
      }
    });
  };

  useEffect(
    async () => {

      setDefaultCredentials({
        accessToken: 'eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfbHFlM3p3Z3UiLCJqdGkiOiI1YjI0OWE2ZCJ9.Y7zB30NJFzq5fPv8W5nkoH5lPXFWQP0uywDtqUg8y8c'
      });
      
      setCurrentSlide(0);
    },
    [setCurrentSlide]
  );

  useEffect(
    () => {
      if (currentSlide !== null) {
        const {mapID: cartoMapId, layers, view, orbit: shouldOrbit} = slides[currentSlide];
        if (cartoMapId) {
          fetchMap({ cartoMapId }).then(({initialViewState: mapViewState, layers: mapLayers}) => {
            const _mapLayers = mapLayers.map(
              l => l.clone({
                minZoom: 11, 
                maxZoom: 11,
                parameters: { depthTest: false }
              })
            );
            setLayers(_mapLayers);
            updateViewState(mapViewState, shouldOrbit);
          });
        }
        else {
          setLayers(layers.map(l => l.clone()));
          if (view && view.longitude !== undefined) {
            updateViewState(view, shouldOrbit);
          }
        }
      }
    },
    [currentSlide]
  );

  return (
    <AppStateContext.Provider
      value={{
        next: () => {
          if (currentSlide < slides.length - 1) {
            setCurrentSlide(currentSlide + 1);
          }
        },
        prev: () => {
          if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
          }
        },
        reset: () => {
          setCurrentSlide(0);
        },
        currentSlide,
        layers,
        slidesNumber: slides.length,
        viewState,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export const AppStateContextConsumer = AppStateContext.Consumer;

export function useAppState() {
  return useContext(AppStateContext);
}

