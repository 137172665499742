import React, {forwardRef, useImperativeHandle, useState, useEffect} from 'react';
import {
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
  Link,
  Paper,
  Divider,
  Hidden
} from '@material-ui/core';
import {ReactComponent as IconNavigationClose} from '../../assets/icons/icon-navigation-close.svg';
import {ReactComponent as IconGithub} from '../../assets/icons/icon-github.svg';
import cartoLogo from '../../assets/images/carto-components-logo.svg';
import codeImage from '../../assets/images/code.svg';
import AboutText from './AboutText';
import redshiftLogo from '../../assets/images/redshift-logo.svg';
import amazonLocationServiceLogo from '../../assets/images/amazon-location-service.png';

const ABOUT_HASH = 'about';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: 0,
    maxHeight: '100%',
    height: '100%',
    borderRadius: 0,
    [theme.breakpoints.up('md')]: {
      maxHeight: `calc(100% - ${theme.spacing(18)}px)`,
      height: 'auto',
      margin: theme.spacing(4),
      borderRadius: theme.shape.borderRadius
    }
  },
  title: {
    padding: theme.spacing(3),
    position: 'relative'
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(2),
    top: '50%',
    transform: 'translateY(-50%)',
    '& svg path': {
      fill: theme.palette.text.secondary
    }
  },
  content: {
    padding: theme.spacing(2, 3, 0, 3)
  },
  logosText: {
    marginBottom: theme.spacing(2),
    fontWeight: 400
  },
  card: {
    padding: theme.spacing(3, 3, 4.5),
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    alignItems: 'center'
  },
  cardText: {
    flex: 1,
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3)
    }
  },
  cardTextTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1)
  },
  cardTextButtons: {
    marginTop: theme.spacing(3),
    '& > a + a': {
      marginTop: theme.spacing(1.5)
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      '& > a + a': {
        marginLeft: theme.spacing(1),
        marginTop: 0
      }
    }
  },
  climateEngineLogo: {
    maxWidth: theme.spacing(17)
  },
  divider: {
    margin: theme.spacing(4.5, 0, 2)
  }
}));

const About = ({}, forwardedRef) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const hashChangeListener = () => {
      setOpen(window.location.hash === `#${ABOUT_HASH}`);
    };
    window.addEventListener('hashchange', hashChangeListener);
    hashChangeListener();
    return () => {
      window.removeEventListener('hashchange', hashChangeListener);
    };
  }, [setOpen]);

  useImperativeHandle(forwardedRef, () => ({
    show: () => {
      setOpen(true);
    }
  }));

  return (
    <Dialog
      open={open}
      scroll="paper"
      aria-labelledby="about-title"
      aria-describedby="about-description"
      onClose={() => {
        window.location.hash = '';
      }}
      classes={{
        paper: classes.paper
      }}
    >
      <DialogTitle id="about-title" disableTypography classes={{root: classes.title}}>
        <Typography color="textPrimary" variant="h6">
          About this story map
        </Typography>
        <IconButton
          classes={{root: classes.closeBtn}}
          aria-label="Close"
          color="inherit"
          onClick={() => {
            window.location.hash = '';
          }}
        >
          <IconNavigationClose />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{root: classes.content}}>
        <DialogContentText component="div" id="about-description" tabIndex={-1}>
          <AboutText
            title="Amazon Redshift + CARTO + deck.gl + Amazon Location Service = Great rich map visualizations"
            imageBlocks={[
              {
                title: 'With data stored in',
                textClassName: classes.logosText,
                mobileHorizontal: true,
                images: [
                  {
                    src: redshiftLogo,
                    alt: 'Redshift'
                  }
                ]
              },
              {
                title: 'Connected by',
                textClassName: classes.logosText,
                mobileHorizontal: true,
                images: [
                  {
                    src: cartoLogo,
                    alt: 'CARTO'
                  }
                ]
              },
              {
                title: 'Displayed on top of',
                textClassName: classes.logosText,
                mobileHorizontal: true,
                images: [
                  {
                    src: amazonLocationServiceLogo,
                    alt: 'Amazon Location Service'
                  }
                ]
              }
            ]}
          >
            <p>
              CARTO is been working on integrating a rich set of visualization
              capabilities. The data behind it is stored on{' '}
              <Link underline="always" href="https://aws.amazon.com/redshift/" target="_blank">
                Amazon Redshift
              </Link>{' '}
              and visualized using{' '}
              <Link underline="always" href="https://carto.com" target="_blank">
                CARTO
              </Link>{' '}
              and the{' '}
              <Link underline="always" href="https://deck.gl/" target="_blank">
                deck.gl
              </Link>{' '}
              visualization library. Some maps are made of very large datasets and others come directly from
              SQL.
            </p>
          </AboutText>

          <Paper classes={{root: classes.card}} elevation={0}>
            <div className={classes.cardText}>
              <Typography variant="overline" component="h4" className={classes.cardTextTitle}>
                How has this been made
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                The CARTO Spatial Extension for Redshift offers a simple way to add tables or direct SQL from Amazon Redshift.
              </Typography>
              <div className={classes.cardTextButtons}>
                <Button
                  href="https://carto.com/blog/geospatial-analytics-aws-carto-spatial-extension-redshift/"
                  component="a"
                  target="_blank"
                  variant="contained"
                  size="small"
                  color="primary"
                >
                  Read the blog
                </Button>
                <Button
                  href="https://github.com/CartoDB/aws-demo"
                  component="a"
                  target="_blank"
                  size="small"
                  color="primary"
                  startIcon={<IconGithub />}
                >
                  Check it on GitHub
                </Button>
              </div>
            </div>
            <Hidden smDown>
              <img src={codeImage} />
            </Hidden>
          </Paper>

          <Divider className={classes.divider} />

          <AboutText
            title="Data sources"
            imageBlocks={[]}
          >
            <p>
              All the data used for this visualization is available as public data in Redshift (
              <strong>carto-demo-data</strong>). The original sources for the data are:
            </p>
            <ul>
              <li>
                <Link
                  href="https://xxxx"
                  target="_blank"
                >
                  xxxx data
                </Link>
              </li>
              <li>
                <Link href="https://carto.com/data" target="_blank">
                  Demographics, boundaries and other extra layer from CARTO Data Observatory
                </Link>
              </li>
            </ul>
          </AboutText>

          <Divider className={classes.divider} />

          <AboutText title="Credits" imageBlocks={[]}>
            <ul>
              <li>
              </li>
            </ul>
          </AboutText>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default forwardRef(About);
