import {CartoLayer, colorBins, MAP_TYPES} from '@deck.gl/carto';
import Blending from './blending';

export const TripsLayer = new CartoLayer({
    id: 'trips',
    connection: 'sf-development',
    type: MAP_TYPES.TILESET,
    data: 'demo_db.ny_citibike.trips_aggregated_10m',
    getFillColor: colorBins({
      attr: 'aggregated_total',
      domain: [100, 500, 1000, 5000, 15000],
      colors: 'Teal'
    }),
    pointRadiusMinPixels: 1.5,
    stroked: false,
    parameters: {
      depthTest: false,
      ...Blending.ADDITIVE
    }
});

