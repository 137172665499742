import {CartoLayer, MAP_TYPES} from '@deck.gl/carto';
import Blending from './blending';
// import bicycleIcon from '../assets/icons/bicycle.svg';

const ICON_MAPPING = {
  marker: {x: 0, y: 0, width: 150, height: 150, mask: true}
};

export const CitiBikeStationsLayer = new CartoLayer({
    id: 'stations',
    connection: 'sf-development',
    type: MAP_TYPES.TABLE,
    data: 'DEMO_DB.NY_CITIBIKE.STATIONS',

    pointRadiusMinPixels: 4,
    lineWidthMinPixels: 1,
    getFillColor: [50, 100, 200],
    getLineColor: [255, 255, 255],

    // pointType: 'icon',
    // getIconSize: d => 15,
    // getIconColor: d => [41, 181, 232], 
    // getIcon: d => 'marker',
    // iconMapping: ICON_MAPPING,
    // iconAtlas: bicycleIcon,

    parameters: {
      depthTest: false,
      // ...Blending.ADDITIVE
    }
});

